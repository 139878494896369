@import "npm:material-icons/iconfont/material-icons.css";
@import "npm:@materializecss/materialize/sass/materialize";

html {
  hyphens: auto;
}
body {
  background-color: #fcfcfc;
}

main {
  h2,
  h3 {
    text-transform: uppercase;
    text-align: center;
  }

  h2 {
    font-size: 1.64rem;
    margin: 1.093rem 0 0.656rem;
  }
  h3 {
    font-size: 1.15rem;
    margin: 0 0 0.46rem;
  }

  h3.head {
    margin-top: 20px;
    margin-bottom: 35px;
  }
}

header a {
  text-transform: uppercase;
}
footer a {
  margin-left: 1em;
}

strong {
  font-weight: 700;
}
p {
  text-align: justify;
}

.justify {
  text-align: justify;
}
.small-hr {
  width: 50px;
  margin-top: 20px;
}
.larger-text {
  font-size: 130%;
}
.round-corners {
  border-radius: 5px;
}
ul.disc-list li {
  list-style-type: disc;
}

.intro {
  padding: 13%;
  margin-bottom: 50px;

  h1 {
    display: inline-block;
    padding: 20px;
    margin: 0;

    line-height: 1;
    font-size: 1rem;
  }
}
