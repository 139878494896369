
// Collections
.collection {
  margin: $element-top-margin 0 $element-bottom-margin 0;
  border: 1px solid $collection-border-color;
  border-radius: $collection-border-radius;
  overflow: hidden;
  position: relative;

  .collection-item {
    background-color: $collection-bg-color;
    line-height: $collection-line-height;
    padding: 10px 20px;
    margin: 0;
    border-bottom: 1px solid $collection-border-color;

    // Avatar Collection
    &.avatar {
      min-height: 84px;
      padding-left: 72px;
      position: relative;

      // Don't style circles inside preloader classes.
      &:not(.circle-clipper) > .circle,
      :not(.circle-clipper) > .circle {
        position: absolute;
        width: 42px;
        height: 42px;
        overflow: hidden;
        left: 15px;
        display: inline-block;
        vertical-align: middle;
      }
      i.circle {
        font-size: 18px;
        line-height: 42px;
        color: #fff;
        background-color: #999;
        text-align: center;
      }


      .title {
        font-size: 16px;
      }

      p {
        margin: 0;
      }

      .secondary-content {
        position: absolute;
        top: 16px;
        right: 16px;
      }

    }


    &:last-child {
      border-bottom: none;
    }

    &.active {
      background-color: $collection-active-bg-color;
      color: $collection-active-color;

      .secondary-content {
        color: #fff;
      }
    }
  }
  a.collection-item{
    display: block;
    transition: .25s;
    color: $collection-link-color;
    &:not(.active) {
      &:hover {
        background-color: $collection-hover-bg-color;
      }
    }
  }

  &.with-header {
    .collection-header {
      background-color: $collection-bg-color;
      border-bottom: 1px solid $collection-border-color;
      padding: 10px 20px;
    }
    .collection-item {
      padding-left: 30px;
    }
    .collection-item.avatar {
      padding-left: 72px;
    }
  }

}
// Made less specific to allow easier overriding
.secondary-content {
  float: right;
  color: $secondary-color;
}
.collapsible .collection {
  margin: 0;
  border: none;
}